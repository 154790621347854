<template>
  <div class="prompt-library">
    <i class="bx bx-plus box-icon" title="Add a new Prompt" @click="toggleRequestAdded()"></i>
    <form v-if="requestAdded" class="prompt-library__form" @submit.prevent="isPromptEdited ? updatePrompt() : createNewPrompt()">
      <div class="form-group">
        <label for="prompt-title">Enter the Prompt Label</label>
        <EdInput
          placeholder="Explain this as charles dickens"
          :value="label"
          :onChange="setPromptLabel"
          Class="large-font"
        />
      </div>
      <div class="form-group mt-4">
        <label for="prompt-title">Choose Visibility</label>
        <ed-dropdown
          :listItems="availableVisibilites"
          :selected="selectedVisibility"
          :isSelected="setVisibility"
          styles="max-width: 8rem"
        />
      </div>
      <div class="form-group mt-4">
        <label for="prompt-description">Enter the Prompt here</label>
        <mavon-editor
          rows="6"
          id="note-input"
          placeholder="Add to note..."
          class="mavonEditor"
          :showShortCut="false"
          v-model="prompt"
          :language="'en'"
          :subfield="false"
          :toolbars="mavonToolbar"
          @save="savePrompt"
        />
      </div>
      <div class="form-group mt-4">
        <button class="ed-btn ed-btn__blim">
          <span v-if="isPromptEdited">Update Prompt</span>
          <span v-else>Create Prompt</span>
          <span class="spinner-border spinner-border-sm"
          :style="{ opacity: isLoading ? 1 : 0, marginLeft: '0.5rem' }"
          ></span>
        </button>
      </div>
    </form>
    <div class="prompt-library__prompts mt-4">
      <h4 class="prompt-library__heading">
        Available Prompts
      </h4>
      <hr/>
      <ul class="prompt-library__list-items d-flex flex-column">
        <li class="prompt-library__list-item d-flex justify-content-between align-items-center" v-for="prompt in prompts" :key="prompt.id">
          <div class="prompt-library__list-item__body">
            {{ prompt.label }}
          </div>
          <div class="prompt-library__list-item__tools">
            <i class="bx bx-edit box-icon" title="Edit Prompt" @click="editPrompt(prompt)"></i>
            <i class="bx bx-trash box-icon" title="Delete Prompt" @click="deletePrompt(prompt.id)"></i>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import EdInput from '@/common/components/ed-forms/ed-input.vue';
import EdDropdown from '@/common/components/ed-forms/ed-dropdown.vue';
import mavenEditorConfigs from '@/config/mavenEditor';

export default {
  name: 'promptLibrary',
  components: {
    EdInput,
    EdDropdown,
  },
  async mounted() {
    await this.fetchPrompts();
  },

  computed: {
    prompts() {
      return this.$store.getters['quests/allPrompts'];
    },
  },

  data() {
    return {
      mavonToolbar: mavenEditorConfigs.mavonToolbar,
      label: '',
      prompt: '',
      selectedVisibility: 'public',
      availableVisibilites: ['public', 'private', 'custom'],
      isLoading: false,
      requestAdded: false,
      isPromptEdited: false,
    };
  },
  methods: {
    async fetchPrompts() {
      await this.$store.dispatch('quests/fetchAllPrompts');
    },
    setPromptLabel(value) {
      this.label = value;
    },
    setVisibility(value) {
      this.selectedVisibility = value;
    },
    async createNewPrompt() {
      this.isLoading = true;
      await this.$store.dispatch('quests/createPrompt', {
        label: this.label,
        prompt: this.prompt,
        visibility: this.selectedVisibility,
      });
      this.isLoading = false;
      this.label = '';
      this.prompt = '';
    },
    async updatePrompt() {
      console.log('xvf', 'prompt is updated');
      this.isLoading = true;
      await this.$store.dispatch('quests/updatePrompt', {
        label: this.label,
        id: this.idOfPromptWhichIsCurrentlyEdited,
        visibility: this.selectedVisibility,
        prompt: this.prompt,
      });
      this.isLoading = false;
      this.label = '';
      this.prompt = '';
      this.idOfPromptWhichIsCurrentlyEdited = '';
      this.isPromptEdited = false;
      this.isLoading = false;
    },
    async editPrompt(prompt) {
      this.isPromptEdited = true;
      this.label = prompt.label;
      this.prompt = prompt.prompt;
      this.idOfPromptWhichIsCurrentlyEdited = prompt.id;
      this.requestAdded = true;
    },
    async deletePrompt(id) {
      this.$store.dispatch('quests/deletePrompt', { id });
    },
    toggleRequestAdded() {
      if (this.requestAdded) {
        this.isPromptEdited = false;
        this.label = '';
        this.prompt = '';
      }
      this.requestAdded = !this.requestAdded;
    },
  },
};
</script>

<style lang="scss">
.large-font {
  font-size: 14px;
  line-height: 1.6;
  max-width: unset;
}
.prompt-library {

  &__list-items {
    padding: 0;
    margin: 0;
  }

  &__list-item {
    padding: .5rem 0;
    border-bottom: 1px solid $color-grey-3;
  }
}
</style>
