<template>
  <div>
    <div class="ed-input" :class="inputBoxSize()">
      <input
        type="text"
        name=""
        id=""
        class="ed-input__field"
        :placeholder="placeholder"
        v-model="words"
      />
      <i class="ed-input__icon bx bx-x" @click="clearField" />
    </div>
  </div>
</template>

<script>
import clickOutside from '@/directives/click-outside';

export default {
  name: 'ed-input',
  props: {
    onChange: {
      type: Function,
    },
    placeholder: {
      type: String,
      default: 'Enter the value',
    },
    value: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'large',
    },
    Class: {
      type: String,
      default: '',
    },
  },
  directives: {
    clickOutside,
  },
  data() {
    return {
      words: this.value || '',
    };
  },
  watch: {
    value(val) {
      this.words = val;
    },
    words(val) {
      this.words = val;
      this.onChange(val);
    },
  },
  methods: {
    clearField() {
      this.words = '';
      this.onChange('');
    },
    inputBoxSize() {
      const mySize = this.size || 'large';
      switch (mySize) {
        case 'large': return 'ed-input__large';
        case 'medium': return 'ed-input__medium';
        case 'small': return 'ed-input__small';
        default: return `ed-input__large ${this.Class}`;
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
